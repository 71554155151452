import React, { Component } from 'react'

function TextDisplay({ labelToShow, textToShow, color }) {
  return (
    <div className="text-display-container">
      { labelToShow && <div className="text-display-header">
      <svg width="14" height="28" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7" cy="7" r="7" fill={color}></circle>
      </svg>
        <h5 style={{ marginLeft: '5px' }}>{labelToShow}</h5>
      </div>
      }
      <div className="text-display-content">
        {textToShow}
      </div>
    </div>
  );
}

export default TextDisplay;