import * as d3 from 'd3';
import React, { Component } from 'react'

function EmbeddingButtons({ labelTextToIds, currentLabel, colors, clickLabel}) {
  if (!labelTextToIds) {
    return(<></>);
  }

  const buttons = [];

  for (const [labelText, labelId] of Object.entries(labelTextToIds)) {
    buttons.push(
      <div className="button-m is-white with-border"
          style={{ color: colors[labelId],  borderColor: colors[labelId]}}
          key={labelText}
          onClick={() => clickLabel(labelText, currentLabel)}>
        <div style={{ display: 'flex' }}>
          <div className="text-m" style={{ marginRight: '5px' }} >
            {labelText}
          </div>
          <svg width="14" height="28" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="7" fill={colors[labelId]}></circle>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div className='csv-explore-button-container'>
      {buttons}
    </div>
  );
}

export default EmbeddingButtons;