import React, { useEffect, useState } from 'react';
import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import CSVUploader from './components/CSVUploader';
import ScatterPlot from './components/ScatterPlot';
import EmbeddingPage from './components/EmbeddingPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  Button,
  FormControl,
  Container,
  Spinner
} from 'react-bootstrap';

function App() {
  const [filename, setFilename] = useState();
  const [embeddingCsvUrl, setEmbeddingCsvUrl] = useState();

  let pageBody; // Main section of page is either a model viewer or a list of 

  function handleUpload(csvUrl) {
    const matches = csvUrl.match(/ml\/embeddings\/([a-fA-F\d-]+)\_/);
    return <Redirect to={`/embedding/${matches[1]}/${encodeURI(filename)}`}/>;
  }

  return (
    <Router>
      <Header />
      <Container>
        <Switch>
          <Route exact path="/">
            <h1>How good are your labels?</h1>
            <div className="subheading minor-text">
              <p>
                At Surge, we often wonder about this too. So we built this tool to help you figure out if your labels are really where they need to be.
              </p>
            </div>
            <CSVUploader setEmbeddingCsvUrl={setEmbeddingCsvUrl} setFilename={setFilename}/>
            {embeddingCsvUrl !== undefined && handleUpload(embeddingCsvUrl)}
          </Route>


          <Route path={`/embedding/:uuid/:title`}>
            <EmbeddingPage />
          </Route>
          <Route path={`/embedding/:uuid/`}>
            <EmbeddingPage />
          </Route>

          <Route exact path={`/:method`}>
            <h1>How good are your labels?</h1>
            <div className="subheading">
              <p>
                We often wonder about this too. So we built this tool to help you figure out if your labels are really where they need to be.
              </p>
            </div>
            <CSVUploader setEmbeddingCsvUrl={setEmbeddingCsvUrl} setFilename={setFilename}/>
            {embeddingCsvUrl !== undefined && handleUpload(embeddingCsvUrl)}
          </Route>
        </Switch>
      <Footer />
      </Container>
    </Router>
  );
}

export default App;
