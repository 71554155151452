import React, { useEffect, useState } from 'react';
import '../App.css';

import ScatterPlot from './ScatterPlot';

import { useParams } from "react-router-dom";

//https://surge-embeddings.s3.amazonaws.com/production/ml/embeddings/f86b7911-451f-40a0-a66b-2dd05d8d3bb4_embeddings.csv
function EmbeddingPage() {
  let { uuid, title } = useParams();

  const csvUrl = 
    `https://surge-embeddings.s3.amazonaws.com/production/ml/embeddings/${uuid}_embeddings.csv`
  return (
    <ScatterPlot csvUrl={csvUrl} title={title} />
  );
}

export default EmbeddingPage;