import React, { Component } from 'react'

function Header() {
  return (
    <div role="banner" className="header w-nav">
      <div className="header-content">
        <a href="/" className= "header-brand w-nav-brand">
          <svg width="118" height="42" viewBox="0 0 118 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M49.7965 20.7303C49.46 20.5416 49.1023 20.3932 48.731 20.2882C48.3331 20.1534 47.5594 19.9412 46.3613 19.6472C45.6891 19.5173 45.0249 19.349 44.3719 19.1432C43.5451 18.8227 43.1981 18.396 43.1981 17.6776C43.1981 16.4265 44.2635 15.6262 45.8618 15.6262C47.5395 15.6262 48.899 16.4265 49.644 17.8898L51.3483 16.5326C50.495 14.8017 48.3928 13.6589 45.9679 13.6589C44.478 13.6589 43.2533 14.0582 42.294 14.857C41.835 15.2105 41.4656 15.667 41.2156 16.1896C40.9656 16.7122 40.842 17.2863 40.8549 17.8655C40.8549 19.1454 41.297 20.0495 42.347 20.6618C42.6913 20.8773 43.0573 21.0558 43.439 21.1946C43.8657 21.3294 44.5575 21.5151 45.5434 21.7273C46.368 21.9152 46.9272 22.0213 47.1682 22.1009C47.3941 22.1438 47.6159 22.206 47.8313 22.2866C48.7885 22.6071 49.1577 23.1133 49.1577 23.9378C49.1577 25.3238 47.933 26.1483 46.0961 26.1483C45.2112 26.1621 44.3408 25.9239 43.5861 25.4617C42.8315 24.9995 42.2239 24.3322 41.8342 23.5377L39.9707 24.8972C40.9566 26.922 43.3528 28.1467 46.0961 28.1467C47.6434 28.1467 48.9189 27.72 49.9579 26.8933C50.448 26.5254 50.8439 26.0464 51.1129 25.4957C51.3819 24.945 51.5163 24.3384 51.5052 23.7256C51.5052 22.3551 50.9681 21.3979 49.7965 20.7303Z" fill="white"></path>
<path d="M89.7185 15.9645C88.9449 14.5519 87.287 13.6478 85.4566 13.6478C83.6263 13.6478 82.048 14.3662 80.956 15.7522C79.9701 17.0034 79.4086 18.6812 79.4086 20.5712C79.3728 22.3139 79.9171 24.0192 80.956 25.4189C81.4945 26.0914 82.1805 26.6311 82.9609 26.996C83.7413 27.3609 84.5953 27.5413 85.4566 27.5233C87.2936 27.5233 88.9449 26.617 89.7185 25.2045V26.7761C89.7185 29.4664 88.306 30.9319 85.6423 30.9319C83.6993 30.9319 82.2602 29.893 81.8071 28.1091L79.5789 28.9579C80.326 31.489 82.5631 32.9369 85.6512 32.9369C87.623 32.9369 89.188 32.3776 90.3662 31.2326C91.5444 30.0875 92.1236 28.5424 92.1236 26.5905V13.9131H89.7185V15.9645ZM89.7451 20.8652C89.7451 23.635 88.1468 25.4189 85.8833 25.4189C83.4053 25.4189 81.8071 23.5267 81.8071 20.5712C81.8071 17.6157 83.3544 15.7522 85.8833 15.7522C88.1468 15.7522 89.7451 17.5627 89.7451 20.359V20.8652Z" fill="white"></path>
<path d="M107.772 20.4651C107.772 18.4137 107.239 16.7624 106.174 15.5113C105.108 14.2601 103.669 13.6478 101.832 13.6478C100.929 13.6335 100.034 13.8223 99.2139 14.2004C98.3936 14.5784 97.6687 15.1359 97.0928 15.8318C96.0008 17.136 95.4128 18.9464 95.4128 20.8917C95.4128 22.9143 96.0274 24.7004 97.1172 25.9759C98.2909 27.3862 99.9908 28.1334 101.938 28.1334C104.682 28.1334 106.813 26.6678 107.664 24.1389L105.561 23.3918C105.135 25.0696 103.775 26.0444 101.938 26.0444C99.4891 26.0444 97.8378 24.1544 97.7052 21.2498H107.772V20.4651ZM97.8378 19.4261C98.2644 17.0829 99.7543 15.6969 101.806 15.6969C103.963 15.6969 105.267 17.1625 105.343 19.4261H97.8378Z" fill="white"></path>
<path d="M70.0051 13.913V16.0285V27.8416H72.4013V16.0285H77.8967V13.913H72.4013H70.0051Z" fill="white"></path>
<path d="M113.316 8.66968H114.09L116.112 13.913H115.403L114.87 12.5337H112.516L111.992 13.913H111.289L113.316 8.66968ZM113.687 9.45884L112.752 11.9191H114.636L113.687 9.45884Z" fill="white"></path>
<path d="M117.509 8.66968V13.913H116.809V8.66968H117.509Z" fill="white"></path>
<path d="M63.3759 13.913V22.7286C63.3759 23.576 63.0394 24.3887 62.4405 24.988C61.8415 25.5874 61.029 25.9244 60.1817 25.925C59.3339 25.925 58.5209 25.5883 57.9215 24.9888C57.322 24.3894 56.9853 23.5764 56.9853 22.7286V13.913H54.6001V22.5341C54.6001 24.0173 55.1893 25.4398 56.2381 26.4887C57.287 27.5375 58.7095 28.1267 60.1927 28.1267C61.676 28.1267 63.0985 27.5375 64.1473 26.4887C65.1961 25.4398 65.7854 24.0173 65.7854 22.5341V13.913H63.3759Z" fill="white"></path>
<path d="M28.7015 21.955C27.893 20.5551 26.6772 19.4346 25.2161 18.7428C23.755 18.0509 22.1179 17.8205 20.5226 18.0821L25.7836 15.0448C26.6893 14.5223 27.4832 13.8265 28.12 12.9972C28.7568 12.1678 29.2241 11.2212 29.495 10.2113C29.766 9.20136 29.8353 8.14797 29.6992 7.11125C29.563 6.07452 29.224 5.07477 28.7015 4.16906C28.179 3.26335 27.4832 2.46943 26.6538 1.83263C25.8245 1.19583 24.8778 0.728613 23.8679 0.457661C22.858 0.18671 21.8046 0.117329 20.7679 0.253479C19.7312 0.389628 18.7314 0.728642 17.8257 1.25117L4.02983 9.20906C2.40986 10.1362 1.16734 11.6033 0.519497 13.3537C-0.128343 15.1042 -0.140269 17.0267 0.485804 18.7851C1.11188 20.5434 2.33611 22.0258 3.94444 22.973C5.55278 23.9201 7.44287 24.2718 9.28425 23.9665L4.02319 27.0038C3.11676 27.5259 2.32216 28.2216 1.68484 29.0511C1.04751 29.8805 0.579973 30.8275 0.308965 31.8378C0.0379562 32.8482 -0.031205 33.902 0.105438 34.9391C0.242081 35.9762 0.581847 36.9761 1.1053 37.8818C1.62759 38.788 2.32341 39.5824 3.15296 40.2195C3.98252 40.8566 4.92953 41.324 5.93985 41.5948C6.95016 41.8656 8.00395 41.9345 9.04094 41.7977C10.0779 41.6609 11.0778 41.321 11.9833 40.7975L25.777 32.8396C26.6832 32.3173 27.4776 31.6215 28.1147 30.7919C28.7518 29.9624 29.2191 29.0153 29.4899 28.005C29.7607 26.9947 29.8297 25.9409 29.6929 24.9039C29.5561 23.867 29.2162 22.8671 28.6927 21.9616L28.7015 21.955Z" fill="white"></path>
</svg>
        </a>
        <nav role="navigation" className="header-menu w-nav-menu">
          <div className="header-menu-content">
            <a href="https://surgehq.ai/" className="nav-link w-inline-block">
              <div>About</div>
            </a>
            <a href="https://surgehq.ai/contact" className="nav-link w-inline-block">
              <div>Contact</div>
            </a>
            <a rel="noopener" href="https://calendly.com/surge-ai/introduction?embed_domain=www.surgehq.ai&amp;embed_type=Inline&amp;hide_gdpr_banner=1&amp;month=2021-08" target="_blank" className="button-m w-inline-block">
              <div className="text-m">Schedule Demo</div>
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;