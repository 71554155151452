import React, { Component } from 'react'

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div></div>
        <div className="footer-up">
          <h4 style={{ paddingBottom: '1em' }}>About this project</h4>
          <p className="minor-text">
            Manifold uses document embeddings to visualize up to fifty pieces of text per class for up to six classes. 
            All generated visualizations will be publicly visible to any person with the corresponding link.
          </p>

          <div style={{ display:'flex', marginTop: '0.8em'}} >
            <a className="button-m is-white"
                    href="https://www.surgehq.ai/blog/manifold-using-neural-embeddings-to-explore-the-shape-of-your-data">
              <div className="text-m">
                Learn more about Manifold
              </div>
            </a>
          </div>
        </div>

        <div class="footer-left">
          <div class="h5">Surge AI</div>
          <p className="minor-text">Build powerful NLP datasets using our global labeling force and platform. </p>
          <a rel="noopener" title="Schedule Demo" href="https://calendly.com/surge-ai/introduction?embed_domain=www.surgehq.ai&amp;embed_type=Inline&amp;hide_gdpr_banner=1&amp;month=2021-08" target="_blank" class="footer-demo-link w-inline-block"><div class="h5">Schedule <span class="footer-demo">Demo&nbsp;&nbsp;&nbsp;&nbsp;</span></div></a>
        </div>

        <div className="footer-right">
          <div className="footer-links-box">
            <div className="footer-links-column">
              <div className="h5">Menu</div>
              <div className="footer-links">
                <a href="https://www.surgehq.ai/" className="footer-link w-inline-block">
                  <div className="text-m minor-text">Home</div>
                </a>
                <a href="https://www.surgehq.ai/about" aria-current="page" className="footer-link w-inline-block w--current">
                  <div className="text-m minor-text">About</div>
                </a>
                <a href="https://www.surgehq.ai/about#careers" className="footer-link w-inline-block">
                  <div className="text-m minor-text">Careers</div>
                </a>
                <a href="https://www.surgehq.ai/blog" class="footer-link w-inline-block">
                  <div className="text-m minor-text">Blog</div>
                </a>
                <a href="https://www.surgehq.ai/about" aria-current="page" className="footer-link w-inline-block w--current">
                  <div className="text-m minor-text">Contact</div>
                </a>
              </div>
            </div>
            <div className="footer-links-column">
              <div className="h5">Connect</div>
            <div className="footer-links">
              <a rel="noopener" href="https://twitter.com/hellosurgeai" target="_blank" className="footer-link w-inline-block">
                <div className="text-m minor-text">Twitter</div>
              </a>
              <a rel="noopener" href="https://www.linkedin.com/company/surge-ai/" target="_blank" className="footer-link w-inline-block">
                <div className="text-m minor-text">Linkedin</div>
              </a>
            </div>
          </div>
        <div className="footer-links-column">
          <div className="h5">Get in touch</div>
          <div className="footer-links">
            <a href="mailto:hello@surgehq.ai?subject=Hello%2C%20Surge!" className="footer-link w-inline-block">
              <div className="text-m minor-text">hello@surgehq.ai</div>
            </a>
            <a href="mailto:careers@surgehq.ai?subject=Hire%20me" className="footer-link w-inline-block">
              <div className="text-m minor-text">careers@surgehq.ai</div>
            </a>
          </div>
          </div>
        </div>
        <div className="subfooter">
          <div className="text-s minor-text">2021 © Surge AI. All Rights Reserved</div>
          <div className="subfooter-links">
            <a href="https://www.surgehq.ai/privacy-policy" className="subfooter-link w-inline-block">
              <div className="text-s">Privacy Policy</div>
            </a>
            <a href="https://www.surgehq.ai/terms-of-service" className="subfooter-link w-inline-block">
              <div className="text-s">Terms of Service</div>
            </a>
          </div>
        </div>
      </div>
      </div>
    </footer>
  );
}

export default Footer;