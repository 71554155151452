import React, { Component, useRef, useState } from 'react'
import {parse, stringify, toJSON, fromJSON} from 'flatted';

import files from '../files.png'
import axios from 'axios'
import { CSVReader } from 'react-papaparse'
import { Spinner } from 'react-bootstrap'
import { useParams } from "react-router-dom";


function CSVUploader({ setEmbeddingCsvUrl, setFilename}){
  const ref = useRef();
  const { method } = useParams();
  let prodUrl = 'https://ml-backend.surgehq.ai'
  let devUrl = 'http://127.0.0.1:8000'
  const [fetchInProgress, setFetchInProgress] = useState(false);


  let handleOnDrop = (data) => {
    const filename = ref.current.fileNameInfoRef.current.innerHTML;
    if (filename) {
      setFilename(filename);
    } else {
      setFilename("Your embeddings:");
    }
    data = data.filter((row) => { return row.data['text'] });
    var filtered_data = undefined
    if (data[0].data["label"] === undefined) {
      filtered_data = data.slice(0, Math.min(data.length, 150));
    } else {
      let labels = [...new Set(data.map((row) => row.data["label"]))];
      filtered_data = []
      for (const label of labels) {
        var filtered = data.filter((row) => { return row.data["label"] == label});
        filtered_data = filtered_data.concat(filtered.slice(0, Math.min(filtered.length, 100)));
      }
    }
    data = filtered_data
    setFetchInProgress(true);
    axios.post(`${prodUrl}/embeddings/create/`, { data: data, projection_method: method })
    .then(response => {
      setFetchInProgress(false);
      setEmbeddingCsvUrl(response.data["csv_url"]);
    })
    .catch(error => {
      setFetchInProgress(false);
      console.log(error);
    });
  }

  let handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  let handleOnRemoveFile = (data) => {
    setEmbeddingCsvUrl(undefined);
  }

  return (
    <div className='csv-uploader-container'>
      <div className='csv-drop-container'>
        <div className='csv-uploader'>
          <CSVReader
            ref={ref}
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            onRemoveFile={handleOnRemoveFile}
            config={{header:true}}
            style={{
              border: '1px dashed #BFC6DD',
              borderRadius: '12px',
              color: '#fff',
              dropAreaActive: {
                borderColor: '#3182bd',
                backgroundColor: '#deebf7',
              }
            }}
          >
            <img src={files} />
            <div className="csv-drop-header">
              <span style={{ color: '#782396', textDecoration: 'underline'}}>Browse</span> or drop a CSV file
            </div>
            <div style={{ fontSize: '14px' }}>
              Make sure to have a 'text' and an optional 'label' column
            </div>
          </CSVReader>
        </div>
        {
          fetchInProgress && 
            <div className='spinner-container'>
              <div className='csv-explore-header'>Preparing embeddings...</div>
              <Spinner animation="border" role="status"
                style={{color: '#000', marginTop: "-..px", marginRight: "-..px"}}>
              </Spinner>
            </div>
        }
        {
          !fetchInProgress && <>
            <div className='csv-explore-header'>
            Explore existing datasets:
            </div>
            <div className='csv-explore-button-container'>
              <a className="button-m is-white with-border"
                  href="https://manifold.surgehq.ai/embedding/17d05c93-bf97-4810-baba-921ecf03f339/Toxicity">
                <div className="text-m">
                  Toxicity
                </div>
              </a>

              <a className="button-m is-white with-border"
                  href="https://manifold.surgehq.ai/embedding/c95f9692-defc-488e-813b-699d1e9671b6/Spam">
                <div className="text-m">
                  Spam
                </div>
              </a>

              <a className="button-m is-white with-border"
                  href="https://manifold.surgehq.ai/embedding/ff0cddb6-4824-446f-b46c-485ba88f8354/Airline%20Sentiment">
                <div className="text-m">
                  Airline Sentiment
                </div>
              </a>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default CSVUploader;
