import * as d3 from 'd3';
import React, { useRef, useEffect, useState } from 'react';
import EmbeddingButtons from './EmbeddingButtons';
import InfoNotice from './InfoNotice';
import TextDisplay from './TextDisplay';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';

const COLORS = ["#A028AA", "#FFAA28", "#00DCC8", "#F92672", "#E6DB74", "#BE82FA"];
const DESCRIPTIONS = {
  "18002e77-4859-4357-ac2f-e01eb62513db": (
      <div>
        <p>
          In a <a href="https://www.kaggle.com/c/jigsaw-toxic-comment-classification-challenge/data">toxicity dataset</a> created by Jigsaw,
          human reviewers labeled more than 150,000 Wikipedia comments for six different types of toxic behavior. This visualization displays the first
          fifty toxic and first fifty non-toxic data points from that dataset.
        </p>
        <em>
          Content warning: this dataset contains text that may be considered profane, vulgar, or offensive.
        </em>
      </div>
    ),
  "54784adb-8f8c-4538-acf4-62dc9408741f": (
      <p>
        In a <a href="https://www.kaggle.com/uciml/sms-spam-collection-dataset">collection of SMS spam datasets</a> shared by UCI and built
        by <a href="https://www.dt.fee.unicamp.br/~tiago/smsspamcollection">Tiago A. Almeida and José María Gómez Hidalgo</a>, more than 5000
        SMS messages have been tagged as legitimate or spam. This visualization displays the first fifty spam and first fifty non-spam
        points from that dataset.
      </p>
    ),
  "415895fd-4f4a-4c53-9a0e-08c0f24fe255": (
      <p>
        In an <a href="https://www.kaggle.com/crowdflower/twitter-airline-sentiment">airline sentiment dataset</a>, Figure Eight (formerly Crowdflower)
        collected more than 10,000 Tweets about six different US airlines. Each Tweet was labeled for positive, neutral, or negative sentiment.
        This visualization displays the first fifty positive and first fifty negative Tweets about United Airlines.
      </p>
    )
}

function ScatterPlot({ csvUrl, title }) {
    const ref = useRef();
    const [linkCopied, setLinkCopied] = useState(false);
    const [textToShow, setTextToShow] = useState("");
    const [labelToShow, setLabelToShow] = useState("");
    const [labelTextToIds, setLabelTextToIds] = useState({});
    const [labelClicked, setLabelClicked] = useState("");
    const [selectedText, setSelectedText] = useState("");
    const [shareButtonText, setShareButtonText] = useState("Share");

    var reset = function() {
       d3.selectAll(".data")
          .style("opacity", 0.9)
          .on("click", (d) => { click(d, false) })
          .on("mouseover", mouseover);
    }

    var click = function(d, currentSelected) {
      if (!currentSelected) {
        setTextToShow(d.text);
        setLabelToShow(d.label_text);
        setSelectedText(d.text);
        setLabelClicked("");

        d3.selectAll(".data")
          .filter(function(e){
            return e.text !== d.text;
          })
          .style("opacity",0.2)
          .on("mouseover", (d) => {})
          .on("click", (d) => { click(d, true) });

        d3.selectAll(".data")
          .filter(function(e){
            return e.text === d.text;
          })
          .on("click", reset);
      }
    }

    var clickLabel = function(label, currentLabel) {
      d3.selectAll(".data")
        .style("opacity", 0.9)
        .on("mouseover", mouseover);

      if (currentLabel !== label) {
        d3.selectAll(".data")
          .filter(
            function(e) { return e.label_text !== label; }
          ).style("opacity", 0.2)
          .on("mouseover", (d) => {});
        setLabelClicked(label);
      } else {
        setLabelClicked("");
        reset();
      }
    };

    // stroke="black" stroke-width="3"
    var mouseover = function(d) {
      setTextToShow(d.text);
      setLabelToShow(d.label_text);

      d3.selectAll(".hover-ring")
        .filter(
          function(e) {
            return e.text[0] === d.text[0];
          }
        ).style("opacity", 1)

      d3.selectAll(".hover-ring")
        .filter(
          function(e) { return d.text !== e.text; }
        ).style("opacity", 0)
    }


    var margin = {top: 10, right: 30, bottom: 30, left: 60},
    width = 1200 - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom;

    useEffect(() => {
        const svg = d3.select(ref.current)
            .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .append("g")
                .attr("transform",
                      "translate(" + margin.left + "," + margin.top + ")");
    }, []);

    useEffect(() => {
        draw();
    }, []);

    const draw = () => {
        const svg = d3.select(ref.current);
        d3.csv(csvUrl).then(function(csv_data) {
            const ys = csv_data.map(function(d){ return parseFloat(d.y) });
            const xs = csv_data.map(function(d){ return parseFloat(d.x) });
            const bottom = Math.min.apply(Math, ys);
            const top = Math.max.apply(Math, ys);
            const yPadding = (top - bottom) * 0.1;
            const left = Math.min.apply(Math, xs);
            const right = Math.max.apply(Math, xs);
            const xPadding = (right - left) * 0.1;

            // Add X axis
            var x = d3.scaleLinear()
              .domain([left - xPadding, right + xPadding])
              .range([ 0, width ]);

            // Add Y axis
            var y = d3.scaleLinear()
              .domain([bottom - yPadding, top + yPadding])
              .range([ height, 0]);

            var colorForLabel = function(label) {
              if (label == undefined) {
                return COLORS[0];
              }
              const parsed = parseInt(label);
              if (isNaN(parsed) || parsed > 5){
                return COLORS[0];
              }

              return COLORS[parsed];
            }

            var color = function (d) {
              return colorForLabel(d.label);
            }

            svg.append('g')
              .selectAll(".dot2")
              .data(csv_data)
              .enter()
              .append("circle")
                .attr("cx", function (d) { return x(d.x); } )
                .attr("cy", function (d) { return y(d.y); } )
                .attr("r", 15)
                .attr("class", "hover-ring")
                .style("fill", "white")
                .style("opacity", 0)
                .style("stroke-width", 3)
                .style("stroke", color)

            // Add dots
            svg.append('g')
              .selectAll(".dot")
              .data(csv_data)
              .enter()
              .append("circle")
                .attr("cx", function (d) { return x(d.x); } )
                .attr("cy", function (d) { return y(d.y); } )
                .attr("r", 10)
                .attr("class", "data")
                .style("fill", color)
                .style("opacity", 0.85)
                .style("stroke", "black")
              .on("mouseover", mouseover)
              .on("click", (d) => {
                click(d, selectedText);
              })

            if (csv_data[0].label) {
              for (const row of csv_data) {
                labelTextToIds[row.label_text] = row.label;
                setLabelToShow(csv_data[0].label_text);
              }
            }

            setTextToShow(csv_data[0].text);
            mouseover(csv_data[0]);
          });
    }

    const matches = csvUrl.match(/ml\/embeddings\/([a-fA-F\d-]+)\_/);
    const uuid = matches[1];

    function copyLinkToClipboard() {
      navigator.clipboard.writeText(`https://manifold.surgehq.ai/embedding/${uuid}/${title ? encodeURI(title) : ""}`);
      setLinkCopied(true);
      setShareButtonText("Link copied!")
    }

    return (
      <div>
        <h1>{title ? title : "Your embeddings:"}</h1>
        { DESCRIPTIONS[uuid] &&
          <div className="subheading minor-text">
            { DESCRIPTIONS[uuid] }
          </div>
        }
        <div style={{display: 'flex'}}>
        <a className="button-m is-white" href={csvUrl}
           download="embeddings.csv">
          <div className="text-m">
            Download
          </div>
        </a>
        <div className="button-m is-ghost" onClick={copyLinkToClipboard}>
          <div className="text-m">
            {shareButtonText}
          </div>
        </div>
        </div>
        <div className="embedding-page-container">
          <div className="chart-info-container" style={{ height: '250px', width: '100%' }}>
            <div>
              <InfoNotice />
            </div>
            <TextDisplay labelToShow={labelToShow} textToShow={textToShow}
              color={COLORS[labelTextToIds[labelToShow]]}/>
          </div>
          <div className="chart-container">
            <div className="chart" style={{position: 'relative', flex: 3}}>
                <svg ref={ref}>
                </svg>
            </div>
          </div>

          <EmbeddingButtons
            labelTextToIds={labelTextToIds}
            colors={COLORS}
            currentLabel={labelClicked}
            clickLabel={clickLabel}
          />
        </div>
      </div>
    )
}

export default ScatterPlot;